import {
  ChatController,
} from 'chat-ui-react';
import * as log4js from "log4js";
import io from "socket.io-client";

import { contactChatInput } from './ContactChatInput';
import { ContactChatStartInput } from './ContactChatStartInput';

const SOCKET_SERVER_URL = process.env.REACT_APP_CHAT_SOCKET_SERVER_URL
  ?? "http://127.0.0.1:3001/";
const faitalMessage = "エラーが発生しました。しばらくしてから再度アクセスをお願い致します。"

log4js.configure({
  appenders: {
    console: {type: 'console'}
  },
  categories: {
    default: {appenders: ['console'], level: 'info'}
  }
});
const logger = log4js.getLogger();

export async function ContactChatContinue(chatCtl: ChatController, key: string, socket: any) {
  const socketContent = contactChatInput
  socketContent.key = key
  socketContent.socket = socket
  const contact = await chatCtl.setActionRequest({
    type: 'custom',
    Component: socketContent.function,
  });
  
  const resultType: string[] = contact.type.split('__')

  if (resultType[0] === 'CANCEL') {
    const param = {key, type: resultType[1]}
    socket.emit('leave', param);
    return
  }
  if (resultType[0] === 'error') {
    await chatCtl.addMessage({
      type: 'text',
      content: faitalMessage,
      self: false,
      avatar: '-',
    });
    return
  }
  await ContactChatContinue(chatCtl, key, socket)
}

async function contactChatStart(chatCtl: ChatController): Promise<void> {
  
  await chatCtl.addMessage({
    type: 'text',
    content: `問い合わせ内容を1000文字以内で入力してください。`,
    self: false,
    avatar: '-',
  });

  const contact = await chatCtl.setActionRequest({
    type: 'custom',
    Component: ContactChatStartInput,
  });

  if (contact.type === 'CANCEL') {
    return
  }

  const result: string[] = contact.type.split('__')

  // 初期問い合わせ結果表示
  await chatCtl.addMessage({
    type: 'text',
    content: result[1],
    self: false,
    avatar: '-',
  });

  // 問い合わせ失敗時
  if (result[0] === 'error') {
    return
  }

  const contactKey: string = result[2]

  // Socketサーバへの接続
  const socket = io(SOCKET_SERVER_URL);
  // Socketサーバ接続に成功した場合のイベント処理を定義する
  socket.on("connect", () => {
    console.log(`socketサーバー接続結果: ${socket.connect()}`);
  });
  // サーバ接続に失敗した場合のイベント処理を定義する
  socket.on('connect_error', (error) => {
    logger.error(error)
    chatCtl.addMessage({
      type: 'text',
      content: faitalMessage,
      self: false,
      avatar: '-',
    });
  });

  // 問い合わせkeyを基にオペレータと顧客しか入れないroomにjoinする
  socket.emit('join', contactKey);

  // オペレーターからの問い合わせを表示する
  socket.on("spread message", param => {
    chatCtl.addMessage({
      type: 'text',
      content: param,
      self: false,
      avatar: '-',
      });
  });
  
  await ContactChatContinue(chatCtl, contactKey, socket);
}

export async function ContactChat(chatCtl: ChatController) {
  await contactChatStart(chatCtl);
}
