import {
    Box,
    Button,
    Icon,
    TextField,
} from '@mui/material';
import axios from "axios";
import {
    ChatController,
    TextActionRequest,
    TextActionResponse,
  } from 'chat-ui-react';
  import * as log4js from "log4js";
import React from 'react';
import  { useLocation } from "react-router-dom";

log4js.configure({
  appenders: {
    console: {type: 'console'}
  },
  categories: {
    default: {appenders: ['console'], level: 'info'}
  }
});
const logger = log4js.getLogger();

const faitalMessage = "エラーが発生しました。しばらくしてから再度アクセスをお願い致します。"

const CHATBOT_CONTACT_REGIST_URL = process.env.REACT_APP_CHATBOT_CONTACT_REGIST_URL
  ?? "http://127.0.0.1/api/chatbot_contact_regist/";

const postContact = async(key: string, contact: string) : Promise<any> => {
  const param = { key, sentence: contact};
  try {
    const res = await axios.post(CHATBOT_CONTACT_REGIST_URL,
      param,
      {
        headers: {'content-type': 'application/json'},
        withCredentials: true,
      });
    return res
  } catch (error) {
    logger.error(error);
    return error
  }
}

export function ContactChatStartInput({
    chatController,
    actionRequest,
  }: {
    chatController: ChatController;
    actionRequest: TextActionRequest;
  }): React.ReactElement {
    const chatCtl = chatController;
    const [value, setValue] = React.useState(actionRequest.defaultValue);
    // URLパラメータを取得
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const key = query.get('key')!
  
    const setResponse = React.useCallback(async(): Promise<void> => {
      if (value) {
        const response = await postContact(key, value)!
        let message: any
        if (response.status === 200) {
          const resData = response.data.content;
          message = response.data.success ?
          `success__${resData.message}__${resData.contactChatKey}` : `error__${resData.message}`
        } else {
          message = `error__${faitalMessage}`
        }
        const res: TextActionResponse = { type: message, value};
        chatCtl.setActionResponse(actionRequest, res);
      }
    }, [actionRequest, chatCtl, value]);

    const setResponseCancel = React.useCallback((): void => {
      const type: any = 'CANCEL'
      const res: TextActionResponse = { type, value: '問い合わせを終了する' };
      chatCtl.setActionResponse(actionRequest, res);
      setValue('');
    }, [actionRequest, chatCtl]);

    return (
      <div
        style={{flex: '1 1 auto',}}
      >
      <Box
        sx={{
          flex: '1 1 auto',
          display: 'flex',
          '& > *': {
            flex: '1 1 auto',
            minWidth: 0,
          },
          '& :last-child': {
            flex: '0 1 auto',
          },
        }}
      >
        <TextField
          placeholder={actionRequest.placeholder}
          value={value}
          onChange={(e): void => setValue(e.target.value)}
          autoFocus
          multiline
          variant="outlined"
          maxRows={10}
          inputProps={{ maxLength: 1000 }}
        />
        <Button
          type="button"
          onClick={setResponse}
          disabled={!value}
          variant="contained"
          color="primary"
          startIcon={<Icon>send</Icon>}
        >SEND
        </Button>
      </Box>
      <Box
        sx={{ pt: 1 }}
      >
        <Button
          sx={{
            width: 1,
          }}
          type="button"
          onClick={setResponseCancel}
          variant="contained"
          color="secondary"
          >問い合わせを終了する
        </Button>
      </Box>
      </div>
    );
  }
  