// import {
//   SmsOutlined,
// } from '@mui/icons-material';
import {
    Box,
    Button,
    CssBaseline,
    Divider,
    Link,
    ThemeProvider,
    Typography,
    createTheme,
} from '@mui/material';
import Axios from 'axios';
import {
    ActionRequest,
    AudioActionResponse,
    ChatController,
    FileActionResponse,
    MuiChat,
    SelectActionResponse,
} from 'chat-ui-react';
import * as log4js from "log4js";
import React from 'react';
import {useSearchParams} from "react-router-dom";
import io from "socket.io-client";

import {ContactChat, ContactChatContinue} from './ContactChat';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const {createProxyMiddleware} = require('http-proxy-middleware');

const apiProxy = createProxyMiddleware({
    target: process.env.REACT_APP_BACKEND_URL ?? "http://127.0.0.1",
    changeOrigin: true,
});

const CHATBOT_INIT_URL = process.env.REACT_APP_CHATBOT_INIT_URL
    ?? "http://127.0.0.1/api/chatbot_init/";
const CHATBOT_CONTROLLER_URL = process.env.REACT_APP_CHATBOT_CONTROLLER_URL
    ?? "http://127.0.0.1/api/chatbot_controller/";
const REACT_APP_CHATBOT_CONTACT_SENTENCE_GET_URL = process.env.REACT_APP_CHATBOT_CONTACT_SENTENCE_GET_URL
    ?? "http://127.0.0.1/api/chatbot_contact_sentence_get/";
const SOCKET_SERVER_URL = process.env.REACT_APP_CHAT_SOCKET_SERVER_URL
    ?? "http://127.0.0.1:3001/";
const faitalMessage = "エラーが発生しました。しばらくしてから再度アクセスをお願い致します。"
const contactChatText = 'DYfPt5OnWx7aUZAefqH1uDZGKOYQcS'

log4js.configure({
    appenders: {
        console: {type: 'console'}
    },
    categories: {
        default: {appenders: ['console'], level: 'info'}
    }
});
const logger = log4js.getLogger();

const muiTheme = createTheme({
    palette: {
        primary: {
            main: '#007aff',
        },
    },
});

export function App(): React.ReactElement {
    const [chatCtl, setChatCtl] = React.useState(
        new ChatController({
            showDateTime: false,
        }),
    );

    const [searchParams, setSearchParams] = useSearchParams()

    React.useMemo(() => {
        main(chatCtl, searchParams);
    }, [chatCtl]);

    return (
        <ThemeProvider theme={muiTheme}>
            <CssBaseline/>
            <Box sx={{height: '100%', backgroundColor: 'gray'}}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '80%',
                        maxWidth: '640px', // 480px
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        /** bgcolor: 'background.default', */
                        bgcolor: 'white',
                        justifyContent: 'flex-end',
                        /** alignItems: 'flex-end', */
                    }}
                >
                    <Typography sx={{p: 1, textAlign: 'center'}}>
                        チャットサポート
                    </Typography>
                    <Divider/>
                    <Box sx={{flex: '1 1 0%', minHeight: 0}}>
                        <MuiChat chatController={chatCtl}/>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

// ヘッダ部分にチャットiconを付けようとしたが縦位置がテキストとズレるため、
// 一旦コメントアウトしている。
// <SmsOutlined />{' '}

async function displayMessage(chatCtl: ChatController, text: string, self = false) {
    await chatCtl.addMessage({
        type: 'text',
        content: `${text}`,
        self,
        avatar: '-',
    });
}

async function displayChoice(chatCtl: ChatController, choice: any) {
    const choiceDict: any = {};
    const optionList: any = [];

    Object.keys(choice).forEach((key) => {
        // optionList.push({'value': key, 'text': choice[key].replace(/\n/g, '<br>')});
        optionList.push({'value': key, 'text': choice[key]});
    })

    choiceDict.type = 'select';
    choiceDict.options = optionList;
    const returnValue = await chatCtl.setActionRequest(choiceDict);
    return returnValue
}

async function displayInputForm(chatCtl: ChatController) {
    const text = await chatCtl.setActionRequest({
        type: 'text',
        placeholder: '',
    });
    return text;
}

async function controlChatbot(chatCtl: ChatController, value: string,
                              params: { [key: string]: string }, searchParams: URLSearchParams) {
    const keyParam = searchParams.get('key');

    try {
        const response = await Axios.post(CHATBOT_CONTROLLER_URL,
            {'value': value, 'auth_key': keyParam},
            {
                headers: {'content-type': 'application/json'},
                withCredentials: true,
            });

        const responseData = response.data;
        let choiceDict = null;

        if (responseData.success) {
            logger.info(responseData);

            // リアルタイムチャットへの処理
            if (responseData.graph.length > 1 && responseData.graph[1].text === contactChatText) {
                await ContactChat(chatCtl)
                const topValue = 'top'
                controlChatbot(chatCtl, topValue, params, searchParams);
                return
            }

            let inputtedValue = '';

            const userInputRequired = responseData.isUserTextInputRequired;

            responseData.graph.forEach((elementValue: any) => {
                // テキストの吹き出しは複数表示するケースがあるためループで処理する
                if (elementValue.text == null) {
                    return;
                }
                logger.info(`elementValue: ${elementValue.text}`);

                const displayText = elementValue.text;
                if (elementValue.choice) {
                    choiceDict = elementValue.choice;
                }
                displayMessage(chatCtl, displayText);
            });

            logger.info(`userInputRequired: ${userInputRequired}`)
            if (userInputRequired) {
                inputtedValue = (await displayInputForm(chatCtl)).value;
            } else {
                inputtedValue = (await displayChoice(chatCtl, choiceDict)).value;
                inputtedValue = valueToKey(inputtedValue, choiceDict);
            }
            // const displayInputtedValue = displayInputForm(chatCtl);
            // console.log(`displayInputtedValue:${displayInputtedValue}`);
            logger.info(`inputtedValue: ${inputtedValue}`);

            controlChatbot(chatCtl, inputtedValue, params, searchParams);

        } else {
            displayMessage(chatCtl, faitalMessage);
        }
    } catch (error) {
        logger.error(error);
    }
}

// チャットJSライブラリに、選択されたボタンの値の方を取得する方法が見当たらないため、
// 値からキーを検索し、そのキーをgraphchatへの値として設定する。
function valueToKey(value: string, choiceDict: any) {
    let selectedKey = 'unknown';
    // syntax errorになる
    /**
     for (const key of Object.keys(choiceDict)) {
     if (choiceDict[key] === value) {
     selectedKey = key;
     break;
     }
     }
     */
    Object.keys(choiceDict).forEach((key) => {
        if (choiceDict[key] === value) {
            selectedKey = key;
            // break; return false;
        }
    })
    return selectedKey;
}

function queryToObject(params: URLSearchParams) {
    const result: { [key: string]: string } = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of params.entries()) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
}

async function initChatbot(chatCtl: ChatController, url: string, searchParams: URLSearchParams) {
    try {
        const params = queryToObject(searchParams);
        logger.info(`params:${params}`);

        const response = await Axios.post(url, params,
            {
                headers: {'content-type': 'application/json'},
                withCredentials: true
            });
        const responseData = response.data;
        logger.info(`response.data: ${response.data}`);

        if (responseData.success) {
            const graphTop = responseData.graph[0];
            logger.info(`graphTop: ${graphTop}`);
            const displayText = graphTop.text;
            logger.info(`displayText: ${displayText}`);
            const choiceDict = graphTop.choice;
            logger.info(`choiceDict: ${choiceDict}`);

            // dummy to spacing buttons
            displayInputForm(chatCtl);

            displayMessage(chatCtl, displayText);
            if (choiceDict) {
                const choicedValue = (await displayChoice(chatCtl, choiceDict)).value;
                logger.log(valueToKey(choicedValue, choiceDict));

                controlChatbot(chatCtl, valueToKey(choicedValue, choiceDict), params, searchParams);
            } else {
                // for hokutobank
                const inputtedValue = (await displayInputForm(chatCtl)).value;
                controlChatbot(chatCtl, inputtedValue, params, searchParams);
            }
        } else {
            displayMessage(chatCtl, faitalMessage);
        }
    } catch (error) {
        logger.error(error);
        displayMessage(chatCtl, faitalMessage);
    }
}

const postContactGet = async (key: string): Promise<any> => {
    const param = {contactKey: key};
    try {
        const res = await Axios.post(REACT_APP_CHATBOT_CONTACT_SENTENCE_GET_URL,
            param,
            {
                headers: {'content-type': 'application/json'},
                withCredentials: true,
            });
        return res
    } catch (error) {
        logger.error(error);
        return error
    }
}

async function operaterChat(chatCtl: ChatController, searchParams: URLSearchParams) {
    try {
        const params = queryToObject(searchParams);
        logger.info(`params:${params}`);

        if ("contact_key" in params) {
            const contactKey: string = params.contact_key

            const socket = io(SOCKET_SERVER_URL);

            // サーバに失敗場合のイベント処理を定義する
            socket.on('connect_error', (error) => {
                logger.error(error)
                displayMessage(chatCtl, faitalMessage);
            });

            // 問い合わせIDのroomに入室
            socket.emit('join', contactKey);

            // 顧客からの問い合わせを表示するイベントを登録
            socket.on("spread message", ms => {
                displayMessage(chatCtl, ms);
            });

            // 接続されているクライアント数を表示
            socket.emit('join clients count', contactKey);

            // 問い合わせ履歴を表示
            const response = await postContactGet(contactKey)
            if (response.status === 200 && response.data.success === true) {
                response.data.contact.forEach((contact: any) => {
                    if (contact.type === 'OP') {
                        displayMessage(chatCtl, contact.sentence, true);
                    } else {
                        displayMessage(chatCtl, contact.sentence);
                    }
                });
            } else {
                displayMessage(chatCtl, faitalMessage);
            }

            await ContactChatContinue(chatCtl, contactKey, socket)

        } else {
            displayMessage(chatCtl, faitalMessage);
        }

    } catch (error) {
        logger.error(error);
        displayMessage(chatCtl, faitalMessage);
    }
}


async function main(chatCtl: ChatController, searchParams: URLSearchParams): Promise<void> {

    if ("type" in queryToObject(searchParams)) {
        if (queryToObject(searchParams).type === 'OP') {
            operaterChat(chatCtl, searchParams);
        } else {
            displayMessage(chatCtl, faitalMessage);
        }
    } else {
        initChatbot(chatCtl, CHATBOT_INIT_URL, searchParams);
    }
}
